.Container {
  width: 100%;
}

.Referral {
  width: 100%;
  overflow: hidden;
  padding: 24px 0;
}

.ReferralWrap {
  position: relative;
  width: 100%;
  height: 380px; /* 카드의 높이에 맞게 조정 */
}

.nftList {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
}

.nftContainer {
  position: absolute;
  width: 80%; /* 카드의 너비 */
  height: 100%;
  top: 0;
  left: 10%; /* 중앙 정렬을 위해 */
  transition: all 0.3s ease;
}

.ImageContents {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.ImageContents img {
  width: 100%;
  height: 70%;
  object-fit: fill;
}

.nftInfo {
  padding: 14px 20px;
}

.nftTitle p {
  margin: 0;
  font-size: 14px;
  color: #888;
}

.nftTitle h2 {
  margin: 2px 0 0;
  font-size: 18px;
  color: #333;
}

.Container {
  width: 100%;
  margin-top: 24px;
}

.contentsTitle {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.title {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 4px;
}

.title span {
  color: var(--Gray-Gray-500, #386937);
  font-family: "Pretendard Variable";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
}
.title p {
  color: var(--Gray-Gray-500, #1b1e28);
  font-family: "Pretendard Variable";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
}

.date {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
}

.present {
  color: #386937;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
}
