.section {
  width: 100%;
}
.sectionWrap {
  width: 100%;
  padding: 0px 20px;
}
.TextTitleWrap {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 8px;
}

.TextTitleWrap h1 {
  color: #1b1e28;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
}
.TextTitleWraps{
  margin-bottom: 30px;
 
}

.TextTitleWrap p {
  font-size: 14px;
  color: #96989c;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  margin-bottom: 36px;
}

.identifyNum {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formWrap {
  display: flex;
  flex-direction: column;
}

.inputWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 20px;
}

.inputWrap label {
  color: #66686f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
