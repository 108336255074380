.Contents {
  width: 100%;
}
.inputWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  gap: 20px;
}

.styledLabel {
  text-align: left;
  margin-bottom: 10px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: center;
}

.styledLabel h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  color: #1b1e28;
  width: 100%;
}

.identifyNum {
  flex-basis: 100%;
}
.inputContentsFlexCol {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.inputFlexRow {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4px;
}
