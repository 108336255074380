/* claimFAQ.module.css */
.pageContainer {
  width: 100%;
  padding: 20px;
}

.contentWrapper {
  width: 100%;
  margin: 0 auto;
}

.FAQfilterBtn {
  margin-top: 24px;
  margin-bottom: 32px;
}

.FAQfilterBtn ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 12px;
  padding: 0;
  list-style: none;
}

.FAQfilterBtn li {
  background-color: #f8f8f8;
  color: #333;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  user-select: none;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #c5c5c5;
}

.FAQfilterBtn li.active {
  background-color: #7895fc;
  color: white;
  transform: scale(1.02);
}

.FAQfilterBtn li:hover:not(.active) {
  background-color: #e8e8e8;
}

.faqList ul {
  list-style: none;
  padding: 0;
}

.faqList ul li {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: start;
}

.faqList ul li:nth-child(1) {
  border-top: 1px solid #eee;
}

.faqItem {
  border-bottom: 1px solid #eee;
  padding: 20px 0;
}

.faqQuestion {
  cursor: pointer;
  padding-right: 16px;
}

.questionContent {
  display: flex;
  width: 100%;
  align-items: start;
}

.questionContent span {
  text-align: start;
}

.qMark {
  color: #666;
  min-width: 20px;
  margin-left: 20px;
  font-size: 16px;
  line-height: 1.3;
}

.questionText {
  flex: 1;
  color: #1b1e28;
  line-height: 1.3;
  font-size: 16px;
  white-space: pre-line; /* 이 줄을 추가 */
}

.faqAnswer {
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-out;
}

.faqAnswer.show {
  height: auto;
  padding-bottom: 20px;
}

.answerContent {
  border-radius: 8px;
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
  padding: 0 40px;
}

.arrowIcon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  transition: transform 0.1s ease;
}

.rotate {
  transform: rotate(180deg);
}

@media screen and (max-width: 768px) {
  .FAQfilterBtn li {
    font-size: 14px;
  }
  .questionText {
    font-size: 14px;
  }
}
