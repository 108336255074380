.FinishWrap {
  display: inline-flex;
  width: 100%;
  height: 460px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  flex-shrink: 0;
}

.FinishIcon {
  display: flex;
  padding: 0px 20px;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.h3_Finish {
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
}

.p_Finish {
  font-size: 16px;
  text-align: center;
  font-style: normal;
  font-weight: 500;
}

.FinishText {
  color: #a3baa3;
  text-align: center;
  font-family: "Pretendard Variable";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 16px;
}

.FinishTextDeparted {
  color: #77b4da;
  text-align: center;
  font-family: "Pretendard Variable";
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-top: 16px;
}

.bottomText {
  color: #386937;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-decoration-line: underline;
  cursor: pointer;
}

.bottomTextDeparted {
  color: #065286;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-decoration-line: underline;
  cursor: pointer;
}

.buttonContainer {
  width: 100%;
  padding: 0 20px;
}

.buttonWrapper {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 12px;
  overflow: hidden;
}

.button {
  width: 100%;
  padding: 18px 0;
  border: none;
  background: transparent;
  cursor: pointer;
}

.button:disabled {
  cursor: default;
}

.buttonContent {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.buttonText {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.checkImage {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.loading {
  display: inline-flex;
  align-items: center;
  gap: 2px;
  margin-left: 4px;
}

.dotAnimation {
  color: #fff;
  font-size: 16px;
  animation: loading 0.7s infinite alternate;
}

@keyframes loading {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

.dotAnimation:nth-child(1) {
  animation-delay: 0s;
}
.dotAnimation:nth-child(2) {
  animation-delay: 0.2s;
}
.dotAnimation:nth-child(3) {
  animation-delay: 0.4s;
}

.imgWrapper {
  width: 100px;
  height: 100px;
}

.imgFull {
  width: 100%;
  object-fit: cover;
}
