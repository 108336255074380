/* claimUploadModal.module.css */
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  padding: 0 20px;
  border-radius: 10px 10px 0 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  padding-bottom: 22px;
  overflow: hidden;
}

.overflowContents {
  width: 100%;
}

.modalContentTitle {
  display: flex;
  justify-content: space-between;
  margin-top: 26px;
}

.modalContentTitle p {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #1b1e28;
}

.uploadListWrap {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 100%;
}

.uploadList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.uploadList p {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #66686f;
}

.modalContent h3 {
  margin-top: 40px;
  margin-bottom: 28px;
  font-size: 22px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: left;
}

.input {
  display: none;
}

.imagesFlex {
  display: flex;
  gap: 8px;
  width: 100%;
}

.imagesWrap {
  gap: 4px;
  border-radius: 12px;
  display: flex;
  width: 100%;
}

.imagesWrap img {
  display: flex;
  width: 100%;
  border-radius: 12px;
  object-fit: fill;
  border: 1px solid #e8e9ea;
}
.previewContainer {
  display: flex;
  position: relative;
  width: 88px;
  height: 88px;
}

.deleteButton {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 66px;
  top: -4px;
}

@media screen and (min-width: 500px) {
  .modalContent {
    width: 440px;
  }
}
