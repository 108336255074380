.ProgressbarHeader {
  padding-top: 50px;
  margin-bottom: 30px;
  background: #fff;
}
.ProgressbarWrap {
  width: 94%;
  margin: 0 auto;
  max-width: 100%;
  padding-top: 30px;
  z-index: 12;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stepItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.Progressbarline {
  display: flex;
  width: 90%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.flexCenter {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: center;
  height: 100%; /* 이 설정은 부모의 높이가 정의되어 있어야 정상적으로 작동합니다 */
}

.flexColumnCenter {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

/* 프로그래스바 칸 css */
.stepCircle {
  width: 1rem; /* 16px */
  height: 1rem; /* 16px */
  margin-bottom: 0.25rem; /* 4px */
  z-index: 20;
}

.stepCircle_active {
  background-color: #386937; /* Active Step의 배경 색 */
}

.stepCircle_inactive {
  background-color: #a1e9a0; /* Inactive Step의 배경 색 */
}

/*프로그래스바 텍스트*/
.stepText {
  font-size: 10px;
  font-weight: bold;
  margin-top: 1rem; /* Tailwind의 mt-1은 보통 0.25rem이지만, 여기서는 의도를 추측하여 1rem으로 설정 */
  width: 66px;
  text-align: center;
  color: #353535; /* 기본 텍스트 색상 */
}

.stepText_active {
  color: #386937; /* 활성 상태일 때의 색상 */
}

.stepText_inactive {
  color: #b8b9bc; /* 비활성 상태일 때의 색상 (Tailwind의 text-gray-500은 일반적으로 #6b7280이지만 여기서는 예제 색상으로 처리) */
}

.Progressbarline {
  height: 2px;
  background-image: url("/public/images/lines.png");
  background-size: contain; /* 컨테이너에 맞추기 */
  position: absolute;
  bottom: 40px;
  width: 80%;
}
/*  height: 2px;
  background-image: url("/public/images/lines.png");
  background-size: contain; /* 컨테이너에 맞추기 */

.ProgressbarWrap2 {
  width: 90%;
  margin: 0 auto;
  max-width: 100%;
  padding-top: 70px;
  margin-bottom: 40px;
  z-index: 12;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
