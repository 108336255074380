.container {
  width: 100%;
  margin-top: 30px;
  padding-bottom: 40px;
}

.content {
  width: 100%;
  padding: 0 20px;
}

.title {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.title h3 {
  color: #1b1e28;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.title span {
  text-align: left;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #e86565;
}

.contentWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contentsList {
  width: 100%;
  padding: 16px 10px;
  background-color: #ebf0eb;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.contentsListTitle {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.contentsListTitle h3 {
  color: #1b1e28;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.toggleSwitch {
  width: 14%;
  display: flex;
  justify-content: end;
}

.toggleBtnWrap {
  width: 100%;
  border-radius: 14px;
  background: rgba(121, 137, 128, 0.9);
  position: relative;
  cursor: pointer;
}

.toggleBtnWrap.active {
  background-color: #629861;
}

.imageWrap {
  width: 100%;
  padding: 12px;
  position: relative;
  display: flex;
}

.imageBg {
  width: 14px;
  height: 14px;
  background-color: #fff;
  border-radius: 100%;
  position: absolute;
  top: 5px;
  left: 6px;
  transition: left 0.3s ease;
}

.toggleBtnWrap.active .imageBg {
  left: calc(100% - 18px);
}

.boundary {
  height: 1px;
  background-color: #c1d1c1;
  margin: 10px 0px;
}

.contentsList ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.contentsList ul li {
  display: flex;
  gap: 10px;
  width: 100%;
}

.contentsList ul li p {
  font-size: 14px;
  font-weight: 400;
  color: #66686f;
  line-height: 20px;
}

.showMore {
  margin-top: 20px;
  display: flex;
  width: 100%;
  gap: 6px;
  align-items: center;
  justify-content: center;
}

.showMore p {
  font-size: 16px;
  font-weight: 500;
  color: #629861;
}

.showMore img {
  transition: 0.3s;
}
.imgActive {
  transform: rotate(180deg);
}
