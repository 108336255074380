/* modalLayout.module.css */

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modalOverlay_enter {
  opacity: 0;
}

.modalOverlay_enter_active {
  opacity: 1;
  transition: opacity 0.8s ease;
}

.modalOverlay_exit {
  opacity: 1;
}

.modalOverlay_exit_active {
  opacity: 0;
  transition: opacity 0.8s ease;
}

/* 하단 모달 */
.modal_bottom {
  position: absolute;
  bottom: 0;
  background: white;
  padding: 20px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 99%;
  box-sizing: border-box;
  z-index: 1001;
  max-width: 440px;
}

.modal_bottom_enter {
  transform: translateY(100%);
}

.modal_bottom_enter_active {
  transform: translateY(0);
  animation: slideUp 0.3s ease-out forwards;
}

.modal_bottom_exit {
  transform: translateY(0);
}

.modal_bottom_exit_active {
  transform: translateY(100%);
  animation: slideDown 0.3s ease-in forwards;
}

/* 중앙 모달 */
.modal_center {
  position: relative;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 90%;
  box-sizing: border-box;
  z-index: 1001;
  max-width: 400px;
}

.modal_center_enter {
  opacity: 0;
}

.modal_center_enter_active {
  opacity: 1;
  transition: opacity 0.3s ease;
}

.modal_center_exit {
  opacity: 1;
}

.modal_center_exit_active {
  opacity: 0;
  transition: opacity 0.3s ease;
}
