.paymentStatementContents {
  width: 100%;
  margin-top: 24px;
}
.paymentStatementContentsWrap {
  width: 100%;
}
.paymentStatementWrap {
  width: 100%;
  padding: 0 20px;
}

.userInfoCoentsWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.userInfoCoents {
  width: 100%;
  padding: 16px;
  background-color: #ebf0eb;
  border-radius: 12px;
}

.userInfoCoents ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.userInfoCoents ul li {
  display: flex;
  justify-content: space-between;
}
.userInfoCoents ul li p {
  color: #66686f;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
.userInfoCoents ul li span {
  color: #1b1e28;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: right;
}

.userReception {
  display: flex;
  justify-content: end;
  width: 100%;
  margin-top: 12px;
}

.userReception ul {
  display: flex;
  gap: 2px;
}
.userReception ul li:nth-child(odd) {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: right;
  color: #66686f;
}

.userReception ul li:nth-child(2) {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: right;
  color: #386937;
}

.boundaryWrap {
  margin-top: 8px;
  margin-bottom: 12px;
}
.boundary {
  height: 1px;
  background-color: #e8e9ea;
  border-radius: 2px;
}

.explainWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.explainWrap p {
  color: #66686f;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
}

.explainPDF_Wrap {
  width: 100%;
  padding: 12px 16px;
  background-color: #f3f4f6;
  border-radius: 12px;
}

.explainPDF {
  display: flex;
  justify-content: space-between;
}

.explainPDF p {
  color: #1b1e28;
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.boundaryLineWrap {
  margin: 24px 0;
}

.boundaryLine {
  height: 12px;
  background-color: #f3f4f6;
}

/* 알릴 사항 */

.contentsAnnounce {
  width: 100%;
  margin-top: 36px;
  padding-bottom: 20px;
}

.contentsAnnounceWrap {
  width: 100%;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 36px;
}

.announceEtc,
.announceConsumer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.contentsAnnounceWrap span {
  color: #60875f;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.contentsAnnounceWrap p {
  padding: 16px;
  color: #66686f;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
  background-color: #f3f4f6;
  border-radius: 12px;
}
