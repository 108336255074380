.container {
  width: 100%;
}

.containerWrap {
  padding: 0 4%;
}

.titltBox {
  max-width: 100%;
}
.titltWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  margin-top: 30px;
}
.title {
  color: #1b1e28;
  width: 100%;
  line-height: 33.6px;
  font-weight: 600;
  font-size: 24px;
}
.title span {
  width: 100%;
  line-height: 33.6px;
  font-weight: 600;
  font-size: 24px;
}

/*  */

.tripSelect {
  display: flex;
  flex-direction: column;
}

.calendars {
  color: #1b1e28;
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  width: 20%;
}

/* InsertDate input창에 대한 css */

.inputWrap {
  width: 100%;
  display: flex;
  gap: 8px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
}

.calendarBox {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
}

/* 가입자 insertCSS */
.inputWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px; /* 입력창과 에러메시지 사이 간격 */
}
.errorMessage {
  color: #e86565;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.5;
  margin-left: 4px;
}

.InputFlex {
  display: flex;
  gap: 9px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 4px; /* 24px에서 16px로 수정 */
}

.userInpoInputFlex {
  display: flex;
  gap: 9px;
  position: relative;
  margin-bottom: 4px; /* 24px에서 16px로 수정 */
}

.InputContent {
  padding: 16px 16px;
  border-radius: 12px;
  background-color: #f3f4f6;
  outline: none;
  width: 100%;
  font-size: 14px;
  color: #1b1e28;
  line-height: 24px;
  border: 1px solid transparent;
}
.InputContent::placeholder {
  color: #b8b9bc;
}

.inputError {
  outline: 1px solid #e86565;
}

/* Gender.js CSS시작 */
.sectionGender {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.genderContentsBox {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.UserInfoBox {
  display: flex;
  margin-bottom: 4px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-top: 24px;
}

.userInfo {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: #1b1e28;
}
.companionsTotal {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.genderInput {
  position: relative;
  background-color: #f3f4f6;
  width: 100%;
  border-radius: 12px;
}

.faCheckStyle {
  position: absolute;
  right: 16px;
  color: #96989c;
  top: 14px;
  bottom: 0;
  cursor: pointer;
}

.GenderOption {
  position: absolute;
  left: 1px;
  padding: 12px 0;
  z-index: 100;
  background-color: #f3f4f6;
  width: 161px;
  top: 50px;
  border-radius: 0 0 12px 12px;
  border-top: 1px solid #e8e9ea;
  transition: 2s;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 99%;
}

.genderBtn {
  padding: 12px 16px;
  font-size: 14px;
  color: #1b1e28;
}

/* 동반자 코드 */

.companionCheckicon {
  position: absolute;
  right: 16px;
  top: 14px;
  bottom: 0;
  cursor: pointer;
}

.companionIndex_DeleteBtn {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  margin-top: 20px;
  cursor: pointer;
}

.companionIndex {
  color: #66686f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.DeleteBtn {
  display: flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  background-color: #e8e9ea;
  font-size: 12px;
}

.AddcopanionButtonWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  background-color: #ebf0eb;
  padding: 10px 16px;
  width: 50%;
  margin: 0 auto;
  border-radius: 12px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.AddcopanionButton_Text {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}

/* 캘린더 */

.calendarTtitle {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  color: #1b1e28;
}
.input::placeholder {
  color: #b8b9bc;
}
