.HeaderWrap {
  width: 100%;
  background-color: #fff;
  box-shadow: 0 4px 6.3px -3px rgba(0, 0, 0, 0.25);
}

.HeaderContents {
  width: 100%;
  padding: 26px 4%;
}

.ContentsWrap {
  justify-content: space-between;
  display: flex;
  width: 100%;
  margin: 0 auto;
}

.HeaderContents p {
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  color: #1b1e28;
}
