.buttonWrap {
  padding-top: 80px;
  width: 100%;
}
.buttonWrap.on {
  background-color: #eeeeee;
  width: 100%;
}
.buttonWrap.trip {
  background-color: #f3f4f6;
  width: 100%;
}

.buttonBox {
  position: fixed;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 50;
  max-width: 440px;
  padding: 30px 20px 14px 20px;
  width: 100%;
  background: linear-gradient(
    0deg,
    #fff 0%,
    #fff 85%,
    rgba(255, 255, 255, 0) 100%
  );
}


.buttonStyle {
  background-color: #386937;
  color: white;
  font-weight: bold;
  width: 100%;
  padding-top: 1rem; /* 4/4 = 1rem (assuming base font size of 1rem) */
  padding-bottom: 1rem; /* 4/4 = 1rem (assuming base font size of 1rem) */
  border-radius: 12px; /* Assuming base font size of 1rem */
}

/*finish btnBox*/

.finishButtonBOX {
  position: fixed;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 50;
}

/* 모달버튼 */

.ModalbuttonBox {
  width: 100%;
  text-align: center;
  padding-top: 20px;
}

@media screen and (min-width: 500px) {
  .ModalbuttonBox {
    width: 100%;
  }
}

.ModalbuttonBtnStyle {
  background-color: #386937;
  color: white;
  font-weight: bold;
  width: 100%;
  margin: 0 auto;
  padding-top: 1rem; /* 4/4 = 1rem (assuming base font size of 1rem) */
  padding-bottom: 1rem; /* 4/4 = 1rem (assuming base font size of 1rem) */
  border-radius: 12px; /* Assuming base font size of 1rem */
}

.liteButton {
  width: 100%;
  margin-top: 0px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 12px;
  background: #d9e6d9;
  width: fit-content;
  margin-bottom: 12px;
  cursor: pointer;
}

.liteButton p {
  color: var(--Primary-Primary-500, #386937);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}
