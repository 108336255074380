* {
  margin: 0;
  padding: 0;
}

body {
  padding: 0 !important;
}

/* 선택된 오늘의 날짜의 배경색 설정 */
.MuiPickersDay-today {
  border: none !important;
}

.slick-arrow {
  display: none !important;
}

@media (min-width: 500px) {
  .commonModal {
    max-width: 440px;
  }
}

.MuiPaper-root.MuiDialog-paper {
  bottom: 0;
  position: absolute;
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
  border-radius: 14px 14px 0 0;
}

@media (min-width: 500px) {
  .MuiPaper-root.MuiDialog-paper {
    max-width: 440px;
  }
}

.slick-list {
  width: 100%;
  overflow: visible !important;
}

.claimMainSlick_sliderContainer__Ii2Vg {
  height: 0 !important;
}

.slick-track {
  width: 5000px !important;
  display: flex !important;
}

.slick-slide {
  margin-right: 10px; /* 슬라이드 간격 설정 */
}

.MuiTouchRipple-root {
  width: 30px;
  height: 30px;
}

.MuiGrid-item button {
  width: 37px;
  height: 34px;
}
.css-ypiqx9-MuiDialogContent-root {
  padding: 20px !important;
}
