.container {
  width: 100%;
  margin: 0 auto;
}

.title {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 4px;
}

.contentBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.inputWraps {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
  font-size: 14px;
  margin-top: 20px;
}

.inputWraps label {
  font-size: 16px;
}

.button {
  width: 100%;
  background-color: #386937;
  color: white;
  padding: 16px 0;
  border-radius: 12px;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.errorText {
  color: #e86565;
  font-size: 10px;
}

.textAreaWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 30px;
}

.customTextarea {
  width: 100%;
  height: 100px;
  font-weight: 400;
  line-height: 20px;
  color: #1b1e28;
  font-style: normal;
  padding: 16px;
  background-color: #f3f4f6;
  border-radius: 12px;
  font-size: 14px;
  border: none;
  resize: none;
}

.customTextarea::-webkit-scrollbar {
  display: none;
}

.customTextarea {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.textCount {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.currentCount {
  color: #1b1e28;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.slash {
  font-size: 12px;
  line-height: 16px;
  padding: 0 2px;
  color: #b8b9bc;
}

.quotientCount {
  color: #b8b9bc;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.customTextarea:focus {
  outline: none;
  border: 2px solid #386937;
}

.buttonWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.changeMethod {
  width: 100%;
  background-color: #b6d7b9;
  color: white;
  padding: 16px 0;
  border-radius: 12px;
  border: none;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}
