.container {
  width: 100%;
  padding: 0 1.25rem;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.card {
  width: 100%;
  margin: 0 auto;
  background-color: white;
  border-radius: 8px;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1b2b3e;
  margin-top: 5rem;
  margin-bottom: 2rem;
}

.uploadArea {
  border: 2px dashed #e5e7eb;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-bottom: 1rem;
}

.uploadArea:hover {
  border-color: #386937;
}

.uploadIcon {
  width: 48px;
  height: 48px;
  color: #386937;
  margin: 0 auto 1rem;
}

.uploadText {
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.fileName {
  color: #386937;
  font-size: 0.875rem;
  font-weight: 500;
}

.button {
  width: 100%;
  padding: 0.75rem;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-weight: 500;
  transition: all 0.3s ease;
  margin-top: 1rem;
}

.templateButton {
  background-color: white;
  border: 1px solid #386937;
  color: #386937;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem;
}

.templateButton:hover {
  background-color: #386937;
  color: white;
}

.nextButton {
  composes: button;
  background-color: #386937;
  color: white;
  border: none;
  padding: 1rem 0;
}

.nextButton:hover {
  background-color: rgba(56, 105, 55, 0.9);
}

.nextButton:disabled {
  background-color: #d1d5db;
  cursor: not-allowed;
}

.message {
  padding: 1rem;
  border-radius: 6px;
  margin: 1rem 0;
}

.successMessage {
  composes: message;
  background-color: #dcfce7;
  color: #166534;
}

.errorMessage {
  composes: message;
  background-color: #fee2e2;
  color: #991b1b;
}

.fileInput {
  display: none;
}

.buttonIcon {
  width: 16px;
  height: 16px;
}
