.backdrop {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  bottom: 0px;
  background: white;
  padding: 20px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  z-index: 1001;
  position: absolute;
}

@media screen and (min-width: 500px) {
  .modal {
    width: 440px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.closeIcon {
  cursor: pointer;
}

.content {
  margin-top: 20px;
  margin-bottom: 20px;
}

.contentWrap {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.contentWrap span {
  color: #1b1e28;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
  margin-bottom: 12px;
}

.pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 12px;
}

.pagination span {
  font-size: 16px;
}

.previewImages {
  width: 100%;
  height: 400px;
  border-radius: 12px;
  border: 1px solid #ccc;
}

.rightBtn {
  transform: rotate(180deg);
}

.btnWrap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.btnWrap button:nth-child(1) {
  flex-basis: 50%;
  color: #ffffff;
  background-color: #386937;
  padding: 16px 16px;
  border-radius: 12px;
}
.btnWrap button:nth-child(2) {
  flex-basis: 50%;
  background-color: #ebf0eb;
  color: #386937;
  padding: 16px 16px;
  border-radius: 12px;
}
