p {
  color: var(--Gray-Gray-200, #96989c);
  font-family: "Pretendard Variable";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

ul > li {
  color: var(--Gray-Gray-200, #96989c);
  font-family: "Pretendard Variable";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  display: flex;
  margin-bottom: 1px;
}

.ContentsSection {
  width: 100%;
}

.imgBoxSection {
  width: 100%;
  background: linear-gradient(235deg, #fff 40.93%, #fffaec 83.39%);
}

.imgBoxSection img {
  width: 100%;
  object-fit: cover;
}

.imageContentsWrap {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  gap: 12px;
  padding: 0 4%;
}

.imageContentsWrap img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.divingLine {
  width: 100%;
  height: 1px;
  background: var(--Gray-Gray-bg, #f3f4f6);
  margin: 24px 0 24px 0;
}

.textWrap {
  width: 100%;
  padding: 0px 20px 0px 20px;
  margin-bottom: 60px;
}

.textWrap article img {
  width: 100%;
  margin: 0 auto;
}

.listStyle li span {
  width: 10px;
}

.eventText li p,
span {
  color: #96989c;
  font-weight: 400;
  font-size: 12px;
  line-height: 16.8px;
}

.listStyle li p,
span {
  color: #66686f;
  font-size: 12px;
  font-weight: 400;
  line-height: 16.8px;
}
