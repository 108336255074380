.indemnityContents {
  width: 100%;
  padding: 0 6%;
  padding-bottom: 60px;
}

.plansContainer {
  width: 100%;
}

.regularPlansWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.indemnityWrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.title {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  gap: 12px;
}

.title p {
  color: var(--Gray-Gray-500, #1b1e28);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
}

.subTitle {
  display: flex;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  color: #1b1e28;
}

.subTitle p {
  color: #386937;
}

.imageWrap {
  width: 60px;
  height: 60px;
}

.imageWrap img {
  width: 100%;
  object-fit: cover;
}

.checkAnnounce {
  width: fit-content; /* 또는 max-content */
  background-color: white;
  margin: 20px 0;
  border-radius: 14px;
}

.checkAnnounceWrap {
  width: fit-content; /* 또는 max-content */
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 6px 12px;
}

.checkAnnounceWrap p {
  color: #0e98f6;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.wrap {
  border-radius: 12px;
  background-color: #ebf0eb;
  padding: 20px 16px;
  align-self: stretch;
  position: relative;
  cursor: pointer;
}

.wrap.departed {
  background: rgba(14, 152, 246, 0.06);
}

.ContentsFlexRow {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 8px;
}

.ContentsImgWrap {
  width: 50px;
  height: 50px;
}

.ContentsImg {
  width: 100%;
  object-fit: cover;
}

.platformInfoWrap {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: start;
}

.platformName {
  color: var(--Gray-Gray-500, #1b1e28);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 125% */
}

.platformPrice {
  color: var(--Gray-Gray-500, #1b1e28);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 120% */
}

/* 일반 플랜용 스타일 */
.platformDetailWrap {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.platformDetailWrap p {
  color: #66686f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

/* 출국 후 플랜용 스타일 */
.platformDetailWrap_depart {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.platformDetailWrap_depart li {
  color: #66686f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  padding-left: 12px;
  position: relative;
}

.platformDetailWrap_depart li:nth-child(1) {
  display: flex;
  align-items: center;
  gap: 3px;
}

.platformDetailWrap_depart li::before {
  content: "·";
  position: absolute;
  left: 0;
  font-size: 16px;
}

.platformDetailWrap_depart li span {
  color: #0e98f6;
  font-weight: 500;
}

.platformDetailWrap_depart .notice {
  padding-left: 0;
  gap: 4px;
}

.platformDetailWrap_depart .notice::before {
  content: none;
}

.notice p {
  color: #66686f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.recommandModalBtn {
  position: absolute;
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  top: -10px;
  right: 0px;
  border-radius: 14px 14px 0px 14px;
  background-color: #386937;
  cursor: pointer;
  z-index: 1;
}

.recommandModaTitle {
  color: #ffe688;
  letter-spacing: -1px;
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  white-space: nowrap;
}

.selected {
  border: 1px solid #386937;
}

.selected.departed {
  border: 1px solid #0e98f6;
}
