.container {
  width: 100%;
  min-height: 100vh;
  background-color: #fff;
}

.contents {
  max-width: 560px;
  margin: 0 auto;
  padding: 20px 24px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.headers {
  width: 24px;
  height: 24px;
  margin-bottom: 76px;
  cursor: pointer;
}

.headers img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.contentsTitle {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 48px;
}

.logo {
  width: 180px;
  height: auto;
  margin-left: 2px;
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.contentsTitle p {
  font-weight: 500;
  color: #000;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  line-height: 1.3;
  margin: 0;
}

.contentsTitle p span {
  font-weight: 600;
  color: #000;
  font-family: Inter;
  font-size: 28px;
  font-style: normal;
  line-height: 1.3;
}

.boundaryContents {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  margin: 32px 0;
}

.line {
  height: 1px;
  background-color: #c9c9c9;
  flex: 1;
}

.boundaryContents p {
  color: #8a8a8a;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.32px;
  text-align: center;
  padding: 0 8px;
  white-space: nowrap;
}

@media screen and (max-width: 768px) {
  .contents {
    padding: 20px 16px;
  }

  .contentsTitle p,
  .contentsTitle p span {
    font-size: 24px;
  }

  .logo {
    width: 150px;
  }
}

@media screen and (max-width: 360px) {
  .contentsTitle p,
  .contentsTitle p span {
    font-size: 20px;
  }

  .logo {
    width: 130px;
  }

  .boundaryContents p {
    font-size: 12px;
  }
}
