.ClaimContractInfo {
  width: 100%;
  margin-top: 24px;
}

.ClaimContractInfo h3 {
  font-weight: 600;
  font-size: 24px;
  line-height: 33.6px;
  color: #1b1e28;
}

.dataContentsWrap {
  display: flex;
  flex-direction: column;
}

.dataContentsBox {
  width: 100%;
  background-color: #ebf0eb;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 8px;
}

.dataContentsBoxTitleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dataContentsBoxTitleWrap button {
  padding: 4px 12px;
  text-align: center;
  border-radius: 18px;
  background-color: #386937;
  color: #fff;
  font-style: normal;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}
.dataContentsBoxTitleFlexRow {
  display: flex;
  gap: 8px;
  align-items: center;
}
.dataContentsBoxTitleFlexRow p {
  color: #1b1e28;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}
.dataContentsBoxTitleFlexRow span {
  padding: 2px 8px;
  border-radius: 18px;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}

.boundaryLineWrap {
  padding: 12px 0;
}
.boundaryLine {
  height: 1px;
  background-color: #c1d1c1;
}

.userInfoList {
  width: 100%;
}
.userInfoList ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.userInfoList ul li {
  display: flex;
  justify-content: space-between;
}
.userInfoList ul li p {
  color: #66686f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  
}
.userInfoList ul li span {
  color: #1b1e28;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.ClaimContractInfoWrap {
  width: 100%;
  padding: 0 20px;
}

.btnWrap {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 8px;
  margin-top: 42px;
  margin-bottom: 16px;
}

.buttons {
  border-radius: 20px;
  padding: 6px 16px;
  background-color: #f3f4f6;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  color: #96989c;
}

.active {
  background-color: #386937;
  color: white;
}

.disabled {
  opacity: 0.7;
}

.disabledbutton {
  opacity: 1;
}

