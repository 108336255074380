section {
  width: 100%;
}

.betterSmart {
  width: 100%;
  background-color: #f8f8f8;
  padding-top: 97px;
  padding-bottom: 42px;
}

.contents {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.contents h1,
.contents span {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.2px;
}

.contents span {
  position: relative;
  z-index: 1;
}

.contents span::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 5px;
  width: 100%;
  height: 10px;
  background-color: #5fe29a;
  z-index: -1;
}

.hash {
  width: 100%;
}

.hash ul {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 23px;
}

.hash ul li {
  padding: 2.5px 18px;
  color: #40a167;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.36px;
  border-radius: 6px;
  background: rgba(132, 132, 132, 0.1);
}

.HowToUse {
  width: 100%;
}

.HowToUse img {
  width: 100%;
  object-fit: cover;
}

.checkPoint {
  margin-top: 20px;
  width: 100%;
  padding: 0 4%;
}

.checkPointWrap {
  background-color: #e6f5e5;
  padding: 0% 2%;
  padding-bottom: 40px;
  border-radius: 40px;
}

.checkPointExcel {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 49px;
}

.excelPreviewImages {
  margin-top: 14px;
  width: 100%;
}

.checkPointWrap h3 {
  color: #0fc646;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-top: 18px;
  margin-bottom: 32px;
}

.chk_subTitle,
.chk_subTitle span {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.42px;
  text-align: center;
}

.chk_subTitle span {
  position: relative;
  z-index: 1;
}
.chk_subTitle span::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 100%;
  height: 10px;
  background-color: #fff;
  z-index: -1;
}
.ExcelInfo {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-left: 20px;
  margin-top: 4px;
}

.ExcelInfo p {
  color: #686868;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.36px;
}

.checkPointPlan {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.planContents {
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 20px;
  gap: 10px;
}

.previewPriceImage {
  width: 50%;
}

.TIP {
  width: 100%;
  background-color: white;
  padding: 20px 10px;
  border-radius: 20px;
}

.tipTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.tipTitle p {
  color: #007a31;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.tipTextBox {
  padding: 10px 0;
}

.tipTextBox li {
  color: #000;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 121.894%;
  margin-bottom: 5px;
}

.tipTextBox li span {
  color: #007a31;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 121.894%;
  margin-right: 1px;
}

/* 모바일 반응형 스타일 (400px 이하) */
@media screen and (max-width: 400px) {
  .planContents {
    flex-direction: column;
    gap: 15px;
  }

  .checkPointPlan {
    display: flex;
    flex-direction: column;
    gap: 0px;
  }

  .ExcelInfo {
    margin: 0;
    justify-content: center;
  }
  .previewPriceImage {
    width: 100%;
    order: 2;
  }

  .TIP {
    width: 80%;
    order: 1;
    margin-bottom: 10px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .chk_subTitle {
    padding: 0 10px;
    word-break: keep-all;
  }

  .tipTextBox {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tipTextBox li {
    word-break: keep-all;
    padding: 0 5px;
    text-align: center;
    width: 100%;
  }
}
