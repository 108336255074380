/* app.css */
@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

body {
  font-family: "Pretendard Variable", Pretendard, -apple-system,
    BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI",
    "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
  font-weight: 400;
  background: #353535;
  display: flex;
  flex-direction: column;
}

.layoutWrapper {
  flex: 1;
  width: 100%;
  max-width: 100%;
  background: white;
  min-height: 100vh; /* viewport height 사용 */
  position: relative;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 375px) {
  .layoutWrapper {
    width: 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 344px) {
  .layoutWrapper {
    background: #fff;
    width: 100%;
    height: 100%;
  }
}

@media screen and (min-width: 500px) {
  .layoutWrapper {
    width: 100%;
    max-width: 440px;
  }
}

/* 기존 스타일 유지 */
.white-border {
  box-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff,
    0 0 50px #fff, 0 0 60px #fff, 0 0 70px #fff, 0 0 80px #fff, 0 0 90px #fff;
}

@keyframes slideIn {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  25% {
    transform: translateY(75%);
    opacity: 0.25;
  }
  50% {
    transform: translateY(50%);
    opacity: 0.5;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
