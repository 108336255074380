/* errorModal.module.css */
.contents {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
}

.imageWrap {
  width: 100%;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageWrap img {
  width: 100px;
  height: 100%;
  object-fit: cover;
}

.buttonWrap {
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.confirmButton {
  background-color: #386537;
  color: white;
  width: 100%;
  padding: 16px 0;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.cancelButton {
  background-color: #ebf0eb;
  color: #386537;
  width: 100%;
  padding: 16px 0;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
}

.contents h3 {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  color: #1b1e28;
  white-space: pre-line;
}

.modalTitle {
  /* .modalContentWrap p를 .contents p로 변경 */
  color: #1b1e28;
  font-size: 18px;
  letter-spacing: -0.5px;
  text-align: center;
  width: 100%;
  font-weight: 600;
  padding: 10px 0;
}

.commonX {
  width: 100%;
  display: flex;
  justify-content: end;
}
