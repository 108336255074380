/* claimSelectBankModal.module.css */

.ModalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 500;
}

.ModalContents {
  width: 100%;
  height: 600px;
  position: absolute;
  bottom: 0;
  overflow-y: scroll;
  background-color: #fff;
  padding-bottom: 24px;
  border-radius: 12px 12px 0 0;
  z-index: 500;
}
.ModalContents::-webkit-scrollbar {
  display: none; /* Hide scrollbar for Chrome, Safari and Opera */
}
/* For Firefox */
.ModalContents {
  scrollbar-width: none; /* Hide scrollbar for Firefox */
}

@media screen and (min-width: 450px) {
  .ModalContents {
    width: 440px;
  }
}

.ModalContentsWrap {
  width: 100%;
  padding: 20px;
}

.ModalContents_Title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.ModalContents_Title span {
  color: #1b1e28;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin: 20px 0;
}

.ModalContentsWrap h3 {
  color: #1b1e28;
  font-size: 22px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: left;
  margin: 16px 0;
}

.BankMenuContents {
  width: 100%;
}

.BankMenu {
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3칸짜리 그리드 레이아웃 */
  gap: 8px;
  width: 100%;
}

.BankItemWrap {
  background-color: #f3f4f6;
  border-radius: 12px;
}

.BankItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
  padding: 16px 12px;
}

.BankItem span {
  color: #66686f;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
}

.ImagesWrap {
  width: 36px;
  height: 36px;
  border-radius: 18px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ImagesWrap img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 18px;
  object-fit: cover;
}

.ButtonContents {
  display: flex;
  width: 100%;
  gap: 4px;
}

.ButtonContents button {
  background-color: #f3f4f6;
  padding: 6px 16px;
  color: #96989c;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-align: center;
  border-radius: 12px;
}

.ButtonContents button.active{
  background-color: #386937;
  color: #fff;
}