/* searchModal.module.css */
.modal_container {
  height: 420px;
}

.header_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.title {
  color: #353535;
  font-size: 20px;
  font-weight: bold;
  position: relative;
  background: white;
  z-index: 10;
  margin-top: 20px;
  margin-bottom: 10px;
}

.close_button {
  cursor: pointer;
}

.search_container {
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 12px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #f3f4f6;
}

.search_active {
  border: 1.5px solid #386937;
}
.search_active_hasDeparted {
  border: 1.5px solid #0e98f6;
}

.search_input {
  font-size: 14px;
  padding: 6px 8%;
  outline: none;
  background-color: #f3f4f6;
  width: 100%;
}

.delete_button {
  display: flex;
  justify-content: flex-end;
  margin-right: 8px;
}

.search_icon_container {
  display: flex;
}

.search_icon {
  position: absolute;
  cursor: pointer;
  top: 30%;
  right: 90%;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

.results_container {
  border-radius: 8px;
  max-height: 250px;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.results_container::-webkit-scrollbar {
  display: none;
}

.result_item {
  padding: 8px;
  font-size: 14px;
  margin-bottom: 4px;
  color: #1b1e28;
  font-weight: 500;
  cursor: pointer;
}
