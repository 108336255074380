.container {
  width: 100%;
}

.containerWrap {
  width: 100%;
  padding: 0 20px;
  margin-top: 24px;
}

.contents {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
}

.contents h3 {
  color: #1b1e28;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 33.6px */
  margin-bottom: 30px;
}

.inputWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.inputWrap p {
  color: #1b1e28;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.inputWrap span {
  color: #1b1e28;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.textbox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
}

.textbox p {
  color: #1b1e28;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.contentsBox {
  width: 100%;
  padding: 16px;
  background-color: #ebf0eb;
  border-radius: 12px;
}

.FlexCol {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.FlexCol h4 {
  color: #1b1e28;
  font-family: "Pretendard Variable";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
}

.boundary {
  height: 1px;
  background-color: #c1d1c1;
  margin: 12px 0;
}

.FlexCol ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.FlexCol ul li {
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.FlexCol ul li span {
  color: #66686f;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.FlexCol ul li p {
  color: #1b1e28;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.afterPriceContents {
  width: 100%;
  padding: 16px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  align-items: center;
}

.textTtitle {
  color: #7a9b79;
  font-family: "Pretendard Variable";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 171.429% */
}

.textCost {
  color: #386937;
  text-align: right;
  font-family: "Pretendard Variable";
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
