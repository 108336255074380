/* 그리드 시작 */
.containerWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 36px;
  padding: 0 20px;
}

/* 계약 및 사고사항 표 시작 */

.Contents_1nd {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.firstCell {
  display: grid;
  grid-template-columns: 1fr 2fr;
  border-bottom: 1px solid #eee;
}
.firstCell:last-child {
  border-bottom: none;
}

.firstCell_headers {
  padding: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #1b1e28;
  background-color: #ebf0eb;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

/* 계약 및 사고사항 표 끝 */

/* 지급내역 표 시작 */
.Contents_2nd {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Contents_1nd span,
.Contents_2nd span,
.Contents_3nd span,
.Contents_4nd span {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #60875f;
}

.container {
  display: grid;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
}

.doubleCell:nth-child(1) {
  border-bottom: 1px solid #ddd;
}

.headers {
  border-right: 1px solid #ddd;
  padding: 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #1b1e28;
  background-color: #ebf0eb;
}
.cell {
  padding: 12px 12px 16px 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1b1e28;
  background-color: #f3f4f6;
}

.cells {
  width: 100%;
}

.celltitle {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  color: #1b1e28;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  padding: 12px;
  background-color: #ebf0eb;
}

.section {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
}

.section_header {
  grid-row: span 8;
  font-weight: bold;
  padding: 12px;
  border-right: 1px solid #ddd;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #1b1e28;
  width: 100%;
  background-color: #ebf0eb;
}

.section_head {
  border-top: 1px solid #ddd;
}

.section_header:nth-child(even) {
  border-top: 1px solid #ddd;
}

.subcell {
  border-bottom: 1px solid #ddd;
  width: 100%;
  padding: 10px 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.subcell_sub {
  border-bottom: none;
}

.subcell:nth-child(even) {
  border-right: 1px solid #ddd;
  background-color: #ebf0eb;
}
.subcell:nth-child(odd) {
  border-right: 1px solid #ddd;
  background-color: #f3f4f6;
}

.doubleCell {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

/* 지급내역 표 끝 */

/* 3번 그리드 시작 */

.Contents_3nd {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.girdCell {
  display: grid;
  grid-template-columns: 1fr 3fr;
  border-bottom: 1px solid #ddd;
}

.section_header_3nd {
  grid-row: span 4;
  font-weight: bold;
  padding: 12px;
  border-right: 1px solid #ddd;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #1b1e28;
  width: 100%;
  background-color: #ebf0eb;
  border-bottom: 1px solid #ddd;
}

.girdCell:last-child {
  border-bottom: none;
}

.gridcellText_Title {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #1b1e28;
  padding: 12px;
  background-color: #ebf0eb;
  border-right: 1px solid #ddd;
}

.gridcellText_Description {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #1b1e28;
  padding: 12px;
  background-color: #f3f4f6;
}

/* 끝 */

/* 4번 그리드 시작 */

.Contents_4nd {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.gridcellText_Title_4nd {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  padding: 12px;
  background-color: #ebf0eb;
  border-right: 1px solid #ddd;
  color: #1b1e28;
}
.gridcellText_Description_4nd {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  padding: 12px;
  color: #1b1e28;
  background-color: #f3f4f6;
}

.gridcell_Title_TotalPrice {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  color: #1b1e28;
  padding: 12px;
  background-color: #ebf0eb;
}

.gridcell_Description_TotalPrice {
  font-size: 12px;
  font-weight: 700;
  line-height: 16px;
  text-align: left;
  color: #1b1e28;
  padding: 12px;
  background-color: #f3f4f6;
}
