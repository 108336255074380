.sectionWrap {
  width: 100%;
}
.section_1 {
  width: 100%;
  padding: 0 20px;
}
.section_1 h1 {
  color: #1b1e28;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  margin-top: 24px;
  margin-bottom: 36px;
}

.sectionContentsWrap {
  width: 100%;
}

.sectionContentsWrap_Title {
  display: flex;
  gap: 6px;
  align-items: center;
}

.sectionContentsWrap_Title span {
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  background-color: #386937;
  border-radius: 100%;
  color: #fff;
}
.sectionContentsWrap_Title p {
  font-weight: 600;
  font-size: 16px;
  color: #1b1e28;
  font-style: normal;
  line-height: 20px;
}

/* 옵션 선택css 시작 */

.sectionContentsWrap_userSelect {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  margin-bottom: 20px;
}

.sectionContentsWrap_userSelect p {
  color: #66686f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.sectionContentsWrap_SelectOption {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  border-radius: 12px;
}

.sectionContentsWrap_SelectOption.active {
  padding-bottom: 10px;
}

.sectionContentsWrap_SelectOption.nonActive {
  padding-bottom: 0;
}

.selectedOption {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  cursor: pointer;
  border-radius: 12px;
}

.rotated {
  transform: rotate(180deg);
}

.flexRow {
  align-items: center;
  display: flex;
  gap: 10px;
}

.optionsList {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  width: 100%;
  padding: 0 8px;
  justify-content: center;
  align-items: center;
}

.optionsList li {
  padding: 8px 10px;
  border-bottom: 1px solid #eee;
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
}

.sectionContentsWrap_SelectOption ul {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sectionContentsWrap_SelectOption ul > li {
  display: flex;
  align-items: center;
  gap: 10px;
}

.sectionContentsWrap_SelectOption ul > li p {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  color: #1b1e28;
}

.sectionContentsWrap_claimSelect {
  width: 100%;
  margin-top: 18px;
}
.sectionContentsWrap_claimSelectOption {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.sectionContentsWrap_claimSelectOption p {
  color: #66686f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.sectionContentsWrap_claimSelectOption ul {
  display: flex;
  gap: 16px;
  align-items: center;
}

.sectionContentsWrap_claimSelectOption ul li {
  display: flex;
  gap: 8px;
}
.sectionContentsWrap_claimSelectOption ul li p {
  color: #1b1e28;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
.boundaryWrap {
  width: 100%;
  margin: 20px 0;
}
.boundary {
  background-color: #f3f4f6;
  height: 12px;
}

.section_2 {
  width: 100%;
  padding: 0 20px;
}

.accidentInfoWrap {
  width: 100%;
}

.accidentInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.accidentInfoSlect {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 12px;
}

.accidentInfoSlect p {
  color: #66686f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.accidentInfoSlectBox {
  display: flex;
  align-items: center;
  background-color: #f3f4f6;
  justify-content: space-between;
  border-radius: 12px;
}

.accidentInfoSlectBoxWrap {
  width: 100%;
}

.accidentInfoSlectBox_AccodianBtn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
}

.accidentInfoSlectBox_AccodianContents {
  border-top: 1px solid #e8e9ea;
  width: 100%;
  padding: 14px 16px;
}

.accidentInfoSlectBox_AccodianContents ul {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.accidentInfoSlectBox_AccodianContents ul li {
  color: #1b1e28;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  padding: 10px 0;
}

.accidentInfoSlectBox p {
  color: #1b1e28;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.accidentInfoSlectBox span {
  font-size: 14px;
  color: #999;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.accidentInfoDate {
  width: 100%;
  margin-top: 20px;
}

.accidentInfoDateSelect {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.accidentInfoDateSelect p {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

.text {
  color: #1b1e28;
}

.accidentInfoDateSelectContents {
  display: flex;
  width: 100%;
  gap: 8px;
}

.dateInput {
  display: flex;
  padding: 4px;
  justify-content: space-between;
  background-color: #f3f4f6;
  border-radius: 12px;
  width: 100%;
}

.dateInput input {
  background-color: #f3f4f6;
  width: 100%;
}
.dateInput input:focus {
  outline: none;
}

.timeInput {
  width: 35%;
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  background-color: #f3f4f6;
  align-items: center;
  padding: 14px;
}

.timeInput input {
  background-color: #f3f4f6;
  box-sizing: border-box;
  border-radius: 12px;
  width: 35%;
}

.timeInput input:focus {
  outline: none;
}

.claimDetailWrap {
  width: 100%;
  margin-top: 10px;
}
.claimDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.claimDetails p {
  color: #66686f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.textAreaWrap {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.customTextarea {
  width: 100%;
  height: 100px;
  font-weight: 400;
  line-height: 20px;
  color: #1b1e28;
  font-style: normal;
  padding: 16px;
  background-color: #f3f4f6;
  border-radius: 12px;
  font-size: 14px;
  border: none;
  resize: none; /* 크기를 고정 */
}
.customTextarea::-webkit-scrollbar {
  display: none; /* 웹킷 브라우저에서 스크롤바 숨기기 */
}

.customTextarea {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.textCount {
  display: flex;
  justify-content: end;
  align-items: center;
}

.currentCount {
  color: #1b1e28;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}
.slash {
  font-size: 12px;
  line-height: 16px;
  padding: 0 2px;
  color: #b8b9bc;
}

.quotientCount {
  color: #b8b9bc;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.customTextarea:focus {
  outline: none;
  border: 2px solid #386937;
}

.charCount {
  margin-top: 5px;
  font-size: 14px;
  color: #b8b9bc;
}

.contentForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.inputWrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.styledLabel {
  color: #66686f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
}

.section_3 {
  width: 100%;
  padding: 0 20px;
}

.escrowContentsWrap {
  width: 100%;
  margin-top: 24px;
}

.escrowContents {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.escrowContents_span {
  color: #66686f;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}

.accountList {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.secretNumberWrap {
  padding: 0 20px;
  display: flex;
  width: 100%;
  margin-top: 24px;
  gap: 4px;
}

.secretNumberWrap span {
  font-size: 20px;
}

.secretNumberContents_Text {
  color: #66686f;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  padding-bottom: 20px;
}

.buttonWrap {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.buttonBoundary {
  width: 100%;
  height: 25px;
  position: fixed;
  bottom: 72px;
  background-color: #fff;
  left: 50%;
  transform: translateX(-50%);
  filter: blur(10px);
  border-radius: 100%;
}

.buttonBackground {
  padding-top: 3.5rem; /* pt-14 */
  padding-bottom: 1.75rem; /* pb-7 */
  background-color: #fff;
  position: fixed; /* fixed */
  bottom: 0px; /* bottom-[0px] */
  text-align: center; /* text-center */
  width: 100%;
  display: flex;
  justify-content: center;
}

@media screen and (min-width: 500px) {
  .buttonBackground {
    width: 440px;
  }

  .buttonBoundary {
    width: 440px;
  }
}
@media screen and (min-width: 500px) {
  .buttonWrap {
    width: 440px;
  }
}

.relationshipContents {
  width: 100%;
}

.selectRelationShip {
  background-color: #f3f4f6;
  border-radius: 12px;
}

.selectRelationTitle {
  padding: 16px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.selectedRelation {
  font-size: 14px;
  color: #1b1e28;
  font-weight: 400;
}

.defaultRelation {
  font-size: 14px;
  color: #96989c;
  font-weight: 400;
}

.boundary {
  background-color: #e5e7eb;
  height: 1px;
  width: 100%;
}

.relationOptions {
  max-height: 0;
  overflow-y: scroll;
}

.relationOptions.active {
  max-height: 200px;
  padding-bottom: 20px;
}

.relationOptionItem {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  border-bottom: 1px solid #e5e7eb;
}

.relationOptionItem span {
  padding: 20px 16px;
  width: 100%;
  text-align: left;
  font-weight: 400;
  cursor: pointer;
}

.disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: pointer;
}

.custominput {
  width: 100%;
}

