/* css/Comnons/ClaimDeleteModal.module.css */

.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.modal {
  background-color: #fff;
  width: 100%;
  border-radius: 12px 12px 0 0;
  bottom: 0;
  position: absolute;
  z-index: 101;
}

@media screen and (min-width: 500px) {
  .modal {
    width: 440px;
  }
}

.modalWrap {
  width: 100%;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
}

.modalTitle {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-bottom: 24px;
  padding-top: 12px;
}

.modalTitle h3 {
  color: #1b1e28;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.modalTextBox {
  width: 100%;
}

.modalTextBoxWrap {
  width: 100%;
  border-radius: 12px;
  padding: 10px 4px;
  margin-top: 12px;
}

.modalTextBoxWrap ul {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.modalTextBoxWrap ul h3 {
  font-weight: 500;
  font-size: 16px;
}

.modalTextBoxWrap ul li {
  width: 100%;
  display: flex;
  gap: 6px;
}

.modalTextBoxWrap ul li p {
  color: #66686f;
  font-size: 14px;
  font-weight: 400;
  line-height: 16.8px;
  text-align: left;
}
.modalTextBoxWrap ul li span {
  font-size: 16px;
  line-height: 16.8px;
}

.optionContents {
  width: 100%;
  margin-bottom: 12px;
}

.options,
.optionsActive {
  width: 100%;
  position: relative;
  cursor: pointer;
  font-size: 16px;
}

.options::after,
.optionsActive::after {
  content: "";
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.options > div,
.optionsActive > div {
  padding: 16px 12px;
  background-color: #f3f6f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.optionsActive > div {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.options ul,
.optionsActive ul {
  display: none;
  position: absolute;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 10px 0;
  z-index: 1;
}

.optionsActive ul {
  display: block;
}

.options li,
.optionsActive li {
  padding: 14px 12px;
  cursor: pointer;
  font-size: 14px;
}

.options li:hover,
.optionsActive li:hover {
  background-color: #f0f0f0;
}
