.pageWrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.container {
  flex: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  position: relative;
}

.imgWrap {
  width: 34px;
  height: 34px;
  margin-right: auto; /* This will push everything else to the right */
  align-self: flex-start; /* This ensures left alignment within the flex container */
}

.backButton {
  width: 100%;
  height: 100%;
  cursor: pointer;
  padding: 4px;
}

.backButton:hover {
  opacity: 0.8;
}

.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 40px;
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: #333;
  margin-bottom: 24px;
  text-align: center;
}

.contentWrapper {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.serviceCard {
  background-color: rgba(14, 152, 246, 0.05);
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cardContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.textContent {
  flex: 1;
}

.cardTitle {
  font-size: 20px;
  font-weight: 700;
  color: #333;
  margin-bottom: 8px;
  line-height: 1.4;
}

.cardDescription {
  font-size: 16px;
  color: #666;
  line-height: 1.5;
}

.icon {
  width: 48px;
  height: 48px;
  color: #0e98f6;
  margin-left: 16px;
}

.callButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  color: #fff;
  background-color: #0e98f6;
  transition: background-color 0.2s ease;
}

.callButton:hover {
  background-color: #0d86db;
}

.customerService {
  background-color: #f5f5f5;
}

.grayButton {
  background-color: #666;
}

.grayButton:hover {
  background-color: #555;
}

.footerWrapper {
  margin-top: 40px;
}

@media (max-width: 480px) {
  .title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .cardTitle {
    font-size: 18px;
  }

  .cardDescription {
    font-size: 14px;
  }

  .icon {
    width: 40px;
    height: 40px;
  }

  .callButton {
    height: 44px;
    font-size: 15px;
  }

  .footerWrapper {
    margin-top: 32px;
  }
}

.callButton:active {
  transform: scale(0.98);
}

.callButton:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(14, 152, 246, 0.3);
}
