.TextContainer {
  width: 100%;
}

.TextContainerWrap {
  width: 100%;
  padding: 20px 20px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.textTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: left;
  color: #1b1e28;
}

.TextContainerWrap h3 {
  font-weight: 500;
  font-size: 18px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.textFlex {
  display: flex;
  flex-direction: column;
  gap: 2px;
}

.textFlex p {
  display: flex;
}

/*  IndividualInfo_css*/
.sectionText {
  font-size: 14px;
  font-weight: 400;
  color: #1b1e28;
  margin-top: 24px;
  margin-bottom: 8px;
}

.infotitle {
  font-size: 18px;
  line-height: 24px;
  color: #1b1e28;
  font-size: 600px;
}

.contentTitle {
  font-size: 24px;
  font-weight: 600;
  line-height: 33.6px;
  text-align: left;
  color: #1b1e28;
  margin-bottom: 10px;
}
